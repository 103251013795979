import { createAsync, useLocation } from '@solidjs/router';
import { Title, Meta } from '@solidjs/meta';
import { Breadcrumbs, BreadcrumbItem, Page, Section, Heading, Container } from '@troon/ui';
import { ErrorBoundary, Suspense, Show, For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { NotFoundContent } from '../partials/404';
import { RichText, richTextToPlain } from '../components/rich-text';
import { createContentfulRequest } from '../routes/api/content/[model]/_client';

type Props = {
	slug: string;
	breadcrumbs?: Array<{ href: string; title: string }>;
};

export function PlainDocument(props: Props) {
	const data = createAsync(async () => getEntry(props.slug), { deferStream: true });

	const loc = useLocation();

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<NoHydration>
				<Suspense>
					<Show when={data()}>
						{(data) => (
							<>
								<>
									<Title>{data().fields.title} | Troon</Title>
									<Meta name="description" content={richTextToPlain(data().fields.content).slice(0, 155)} />
								</>
								<Container class="pt-4">
									<Suspense>
										<Show when={data()}>
											<Breadcrumbs>
												<For each={props.breadcrumbs}>
													{(crumb) => <BreadcrumbItem href={crumb.href}>{crumb.title}</BreadcrumbItem>}
												</For>
												<BreadcrumbItem href={loc.pathname}>{data()?.fields.title}</BreadcrumbItem>
											</Breadcrumbs>
										</Show>
									</Suspense>
								</Container>
								<Container size="small" class="flex flex-col gap-8">
									<Page>
										<Section>
											<Heading as="h1">{data()?.fields.title}</Heading>
											<RichText document={data().fields.content} />
										</Section>
									</Page>
								</Container>
							</>
						)}
					</Show>
				</Suspense>
			</NoHydration>
		</ErrorBoundary>
	);
}

const getEntry = createContentfulRequest('plain-document');
export const preload = (slug: string) => () => getEntry(slug);
